import { makeAutoObservable } from "mobx";
import ExchangeProvider from "./provider/base.provider";
import { EMA, RSI } from "technicalindicators";
import { addTradeHistory, fetchTradeHistory } from "@/src/actions/trade/trade";

type Strategies = "rsi_ema_crossover" | undefined;

export default class TradingBot {
  provider: ExchangeProvider;
  config: any;
  tradeHistory: any[] = [];
  openPosition: any;
  state: string = "";
  runCount: number = 0;

  constructor(provider: ExchangeProvider, config: any) {
    makeAutoObservable(this, {}, { autoBind: true });
    if (!(provider instanceof ExchangeProvider)) {
      throw new Error("Provider must be an instance of ExchangeProvider");
    }

    this.provider = provider;
    this.config = {
      riskManagement: {
        stopLossPercent: 5, // Stop loss: 5%
        takeProfitPercent: 1, // Take profit: 10%
      },
      ...config,
    };
  }

  async init() {
    await this.fetchTradeHistory();
    await this.prepareOpeningPosition();
  }

  async run(pair: string) {
    this.state = `Running bot for pair: ${pair}, count ${this.runCount++}`;

    if (!!this.openPosition) {
      // Monitor open positions
      this.state += `. Monitoring open position: ${this.openPosition.pair}`;
      await this.manageOpenPositions(pair);
      return;
    }

    // Fetch market data
    const marketData = await this.provider.fetchMarketData(pair);
    const lastPrice = marketData[marketData.length - 1].close;

    // Analyze market data
    const decision = this.analyzeMarketData(marketData);
    this.state += ` .Trade Decision: ${decision.action}`;

    // Execute trade if action is BUY or SELL
    if (decision.action === "BUY" && !this.openPosition) {
      await this.executeTrade("BUY", pair, lastPrice);
    }
  }

  analyzeMarketData(marketData: any, strategy?: Strategies) {
    const averagePrice =
      marketData.reduce((data: number, item: any) => data + item.close, 0) /
      marketData.length; // Example average price
    const closes = marketData.map((item: any) => item.close);
    const lastPrice = marketData[marketData.length - 1].close;

    /// Implement trading strategies rsi_ema_crossover
    if (strategy === "rsi_ema_crossover") {
      const rsi = RSI.calculate({ values: closes, period: 14 });
      const ema50 = EMA.calculate({ values: closes, period: 50 });

      const lastClose = closes[closes.length - 1];
      const lastRSI = rsi[rsi.length - 1];
      const lastEMA = ema50[ema50.length - 1];

      if (lastRSI < 30 && lastClose > lastEMA) {
        return { action: "BUY", reason: "Oversold or bullish signal" };
      } else if (lastRSI > 70 || lastClose < lastEMA) {
        return { action: "SELL", reason: "Overbought or bearish signal" };
      } else {
        return { action: "HOLD", reason: "No trade signal" };
      }
    }

    /// Default trading strategy average price
    if (lastPrice > averagePrice) {
      // Example trading strategy
      return { action: "SELL", reason: "Price above average" };
    } else if (lastPrice < averagePrice) {
      return { action: "BUY", reason: "Price bellow average" };
    }

    return { action: "HOLD", reason: "No trade signal" };
  }

  async executeTrade(type: string, pair: string, price: number) {
    const positionSize = 1; // Example position size
    // const order = await this.provider.placeOrder(
    //   type,
    //   pair,
    //   positionSize,
    //   price,
    // );
    this.state = `Order executed:, ${type}, ${pair}, ${price}, ${positionSize}`;
    const newOrder = {
      type,
      pair,
      entryPrice: price,
      quantity: positionSize,
      stopLoss:
        price * ((100 - this.config.riskManagement.stopLossPercent) / 100),
      takeProfit:
        price * ((100 + this.config.riskManagement.takeProfitPercent) / 100),
      createdAt: new Date(),
    };

    if (type === "BUY") {
      // Add open position
      this.openPosition = newOrder;
    }

    // Log trade history for order placement
    this.logTrade(newOrder);
  }

  async manageOpenPositions(pair: string) {
    const marketData = await this.provider.fetchMarketData(pair);
    const currentPrice = marketData[marketData.length - 1].close;

    // Check stop-loss
    if (currentPrice <= this.openPosition.stopLoss) {
      this.state = `Stop-loss triggered for ${pair} at ${currentPrice}`;
      await this.closePosition(
        "SELL",
        this.openPosition,
        currentPrice,
        "Stop-loss triggered",
      );
    }

    // Check take-profit
    if (currentPrice >= this.openPosition.takeProfit) {
      this.state = `Take-profit triggered for ${pair} at ${currentPrice}`;
      await this.closePosition(
        "SELL",
        this.openPosition,
        currentPrice,
        "Take-profit triggered",
      );
    }
  }

  async closePosition(
    type: string,
    position: any,
    price: number,
    reason: string,
  ) {
    // Close position
    const order = await this.provider.placeOrder(
      type,
      position.pair,
      position.quantity,
      price,
    );
    this.state = `Position closed: ${JSON.stringify(order)}`;

    // Calculate profit/loss
    const profitOrLoss =
      (price - position.entryPrice) *
      position.quantity *
      (type === "SELL" ? 1 : -1);
    this.state = `Profit/Loss for ${position.pair}: ${profitOrLoss}`;

    // Log closed trade
    this.logTrade({
      type,
      pair: position.pair,
      price,
      positionSize: position.quantity,
      profitOrLoss,
      reason,
      timestamp: new Date(),
    });

    // Remove position from open positions
    this.openPosition = undefined;
  }

  async logTrade(trade: any) {
    const newTrade = await addTradeHistory(trade);
    this.tradeHistory.push(newTrade);
  }

  async fetchTradeHistory() {
    const data = await fetchTradeHistory({ limit: 20 });
    if (!data || data.error) {
      console.error("Error fetching trade history", data?.error);
      return;
    }

    this.tradeHistory = data.results || [];
  }

  async prepareOpeningPosition() {
    if (this.tradeHistory.length > 0) {
      const openPositions = this.tradeHistory.filter(
        (item) => item.type === "BUY" && !item.closed,
      );
      if (openPositions.length > 0) {
        this.openPosition = openPositions[0];
      }
    }
  }
}
