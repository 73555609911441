export default class ExchangeProvider {
  exchange: any;
  apiKey: string;
  apiSecret: string;
  constructor(apiKey: string, apiSecret: string) {
    this.apiKey = apiKey;
    this.apiSecret = apiSecret;
  }

  // Abstract methods - to be implemented by subclasses
  async fetchMarketData(symbol: string): Promise<any> {
    throw new Error("fetchMarketData() must be implemented by subclass");
  }

  async placeOrder(
    type: string,
    symbol: string,
    quantity: number,
    price: number,
  ): Promise<any> {
    throw new Error("placeOrder() must be implemented by subclass");
  }

  async getAccountData(): Promise<any> {
    throw new Error("getAccountData() must be implemented by subclass");
  }
}
