import ccxt from "ccxt";
import { httpExternal } from "../../../services/http.service";
import { formatDateTimeSecond } from "@/src/lib/helper";
import ExchangeProvider from "./base.provider";
import { fetchBalance, placeOrder } from "@/src/actions/trade/binance";

export default class BinanceProvider extends ExchangeProvider {
  baseUrl: string;
  constructor(
    apiKey: string,
    apiSecret: string,
    test: boolean | undefined = true,
  ) {
    super(apiKey, apiSecret);
    this.baseUrl = "https://api.binance.com";
    this.exchange = new (ccxt.pro as any)["binance"]();
  }

  async fetchMarketData(symbol: string) {
    const timeframe = "1m";
    const prices = await this.exchange.fetchOHLCV(
      symbol,
      timeframe,
      undefined,
      20,
    );
    const mapPrices = prices.map((price: any) => ({
      timestamp: formatDateTimeSecond(price[0]),
      open: price[1],
      high: price[2],
      low: price[3],
      close: price[4],
      volume: price[5],
    }));

    return mapPrices;
  }

  async placeOrder(
    type: string,
    symbol: string,
    quantity: number,
    price: number,
  ) {
    // TODO: check balance before buy
    // TODO: check coin balance before sell
    const response = await placeOrder({
      pair: symbol,
      direction: type,
      quantity,
      price,
    });
    console.log("response placeOrder", response);
    return response;
  }

  async getAccountData() {
    const response = await fetchBalance();
    console.log("response getAccountData", response);
    return response;
  }
}
