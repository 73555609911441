"use client";
import { enableStaticRendering } from "mobx-react-lite";
import React, { createContext, ReactNode, useContext } from "react";
import { RootStore, RootStoreHydration } from ".";

enableStaticRendering(typeof window === "undefined");

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = "StoreContext";

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }

  return context;
}

export function useAuthStore() {
  const { authenticationStore } = useRootStore();
  return authenticationStore;
}

export function usePostStore() {
  const { postStore } = useRootStore();
  return postStore;
}

export function useCategoryStore() {
  const { categoryStore } = useRootStore();
  return categoryStore;
}

export function useMasterDataStore() {
  const { masterDataStore } = useRootStore();
  return masterDataStore;
}

export function useUserStore() {
  const { userStore } = useRootStore();
  return userStore;
}

export function useRoleStore() {
  const { roleStore } = useRootStore();
  return roleStore;
}

export function usePermissionStore() {
  const { permissionStore } = useRootStore();
  return permissionStore;
}

export function useSalaryStore() {
  const { salaryStore } = useRootStore();
  return salaryStore;
}

export function useRevenueStore() {
  const { revenueStore } = useRootStore();
  return revenueStore;
}

export function useCampaignStore() {
  const { campaignStore } = useRootStore();
  return campaignStore;
}

export function useProjectStore() {
  const { projectStore } = useRootStore();
  return projectStore;
}

export function usePropertyStore() {
  const { propertyStore } = useRootStore();
  return propertyStore;
}

export function useOrganizationStore() {
  const { organizationStore } = useRootStore();
  return organizationStore;
}

export function useRequestContactStore() {
  const { requestContactStore } = useRootStore();
  return requestContactStore;
}

export function useLocationGroupStore() {
  const { locationGroupStore } = useRootStore();
  return locationGroupStore;
}

export function useMenuStore() {
  const { menuStore } = useRootStore();
  return menuStore;
}

export function useOrderStore() {
  const { orderStore } = useRootStore();
  return orderStore;
}

export function useTradingBotStore() {
  const { tradingBotStore } = useRootStore();
  return tradingBotStore;
}

export function RootStoreProvider({
  children,
  hydrationData,
}: {
  children: ReactNode;
  hydrationData?: RootStoreHydration;
}) {
  const store = initializeStore(hydrationData);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

function initializeStore(initialData?: RootStoreHydration): RootStore {
  const _store = store ?? new RootStore();

  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}
